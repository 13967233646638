const EventStatus = (props) => {
    console.log(props.workflowStep)
    switch (props.workflowStep) {
        case 0:
          return(
            <div className="eventStatus blue">
              Отправлена на согласование заместителю директора
            </div>
          )
        case 1:
          return(
            <div className="eventStatus blue">
              Отправлена на согласование автобуса
            </div>
          )
        case 2:
          return(
            <div className="eventStatus">
              Заявка согласована. Изготовление документов
            </div>
          )
        case 3:
          return(
            <div className="eventStatus">
              Приказ подписан
            </div>
          )
        case 4:
          return(
            <div className="eventStatus red">
              Заявка отклонена<br/>
              Коментарий: {props.comment}
            </div>
          )
        default:
          return(
            <div className="eventStatus">
              Новая
            </div>
          )
      }
  }
  
  export default EventStatus;
import './App.css';
import {React, useState} from 'react';
import Heading from './components/Heading';
import AddStep1 from './components/AddStep1';
import AddStep2 from './components/AddStep2';
import AddStep3 from './components/AddStep3';
import PreView from './components/PreView';
import axios from "axios"
import { useNavigate } from "react-router-dom";

const AppNew = () => {
  const navigate = useNavigate();
  const [mainInfo, setMainInfo] = useState({eventName:"",
                                          address: "",
                                          mainFio:"",
                                          mainFioTel: "",
                                          secondFio:"",
                                          secondFioTel: "",
                                          dateStart: null,//
                                          timeStart:"",
                                          timeEnd:"",
                                          type:""})
  const [studentsInfo, setStudentsInfo] = useState([])
  const [studentsObject, setstudentsObject] = useState([])
  const [reSearch, setResearch] = useState([1])
  const [dopInfo, setDopInfo] = useState([])
  const [step, setStep] = useState(1)

  const addToStudentsInfo = (item) => {
    let arr = [...studentsInfo]
    let obj = [...studentsObject]
    if(!(obj.includes(item.aisId.toString()))){
      arr.push(item)
      arr.sort(function(a, b) {
          if ((a.className + a.lastName) < (b.className + b.lastName)) {
            return -1;
          }
          if ((a.className + a.lastName) > (b.className + b.lastName)) {
            return 1;
          }
          return 0;
      });
      obj.push(item.aisId)
      setStudentsInfo(arr)
      setstudentsObject(obj)
    }
  }

  const addAllStudentsInfo = (students) => {
    console.log(students, students.length)
    let arr = [...studentsInfo]
    let obj = [...studentsObject]
    for(const it of students){
      console.log(it)
      arr.push(it)
      obj.push(it.aisId)
    }
    arr.sort(function(a, b) {
      if ((a.className + a.lastName) < (b.className + b.lastName)) {
        return -1;
      }
      if ((a.className + a.lastName) > (b.className + b.lastName)) {
        return 1;
      }
      return 0;
    });
    setStudentsInfo(arr)
    setstudentsObject(obj)
    setResearch(reSearch + 1)
  }


  const replaceStudentsInfo = (item) => {
    //console.log(item.aisId)
    let arr = [...studentsInfo]
    const result = arr.filter((it) => it.aisId !== item.aisId);
    //console.log(result)
    result.push(item)
    result.sort(function(a, b) {
      if ((a.className + a.lastName) < (b.className + b.lastName)) {
        return -1;
      }
      if ((a.className + a.lastName) > (b.className + b.lastName)) {
        return 1;
      }
      return 0;
    });
    //console.log(result)
    setStudentsInfo(result)
  }

  const delFromStudentsInfo = (aisId) => {
      let arr = [...studentsInfo]
      let obj = [...studentsObject]
      const result = arr.filter((it) => it.aisId !== aisId);
      const result1 = obj.filter((it) => it !== aisId);
      setStudentsInfo(result)
      setstudentsObject(result1)
      setResearch(reSearch + 1)
  }

  const addToDopInfo = (fio, tel) => {
    console.log("addtodopinfo", fio, tel)
    let arr = [...dopInfo]
    arr.push({fio: fio, tel: tel})
    setDopInfo(arr)
  }

  const delFromDopInfo = (item) => {
    let arr = [...dopInfo]
    const result = arr.filter((it) => it !== item);
    setDopInfo(result)
  } 

  const sendData = () => {
    let newObj = JSON.parse(JSON.stringify(mainInfo))
    newObj['students'] = studentsInfo
    newObj['helpers'] = dopInfo
    const json = JSON.stringify(newObj);
    axios.post('/api/new', json).then((result) => {
        if(result.status === 200){
          alert('Заявка отправлена на согласование')
          navigate("/app/")
        }
    })
  }

  switch (step) {
    case 1:
      return(
        <div >
          <Heading text="Новая заявка"/>
          <AddStep1 handleGetMainInfo={setMainInfo} mainInfo={mainInfo} handleSetStep={setStep}/>
        </div>
      )
    case 2:
      return(
        <div >
          <Heading text="Новая заявка"/>
          <AddStep2 addToStudentsInfo={addToStudentsInfo} studentsInfo={studentsInfo}
                    studentsObject = {studentsObject}
                    handleSetStep={setStep} delFromStudentsInfo={delFromStudentsInfo}
                    reSearch = {reSearch}
                    replaceStudentsInfo = {replaceStudentsInfo}
                    addAllStudentsInfo = {addAllStudentsInfo}/>
        </div>
      )
    case 3:
      return(
        <div >
          <Heading text="Новая заявка"/>
          <AddStep3 addToDopInfo={addToDopInfo} dopInfo={dopInfo} 
                    handleSetStep={setStep} delFromDopInfo={delFromDopInfo}/>
        </div>
      )
    case 4:
        return(
          <div >
            <Heading text="Новая заявка"/>
            <PreView dopInfo={dopInfo} studentsInfo={studentsInfo} 
                      mainInfo={mainInfo} handleSetStep={setStep}
                      saveText="Отправить" sendData={sendData}/>
          </div>
        )
    default:
      return(
        <div >
          <Heading text="Новая заявка"/>
          <AddStep1 handleGetMainInfo={setMainInfo} mainInfo={mainInfo} handleSetStep={setStep}/>
        </div>
      )
  }
  
}
export default AppNew;

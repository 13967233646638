const EventStatusSmall = (props) => {
    console.log(props.workflowStep)
    switch (props.workflowStep) {
        case 0:
          return(
            <span className="eventStatusSmall blue">
              Согласование
            </span>
          )
        case 1:
          return(
            <span className="eventStatusSmall blue">
              Согласование
            </span>
          )
        case 2:
          return(
            <span className="eventStatusSmall">
              Согласовано
            </span>
          )
        case 3:
          return(
            <span className="eventStatusSmall">
              Выполнено
            </span>
          )
        case 4:
          return(
            <span className="eventStatusSmall red">
              Отклонено
            </span>
          )
        default:
          return(
            <span className="eventStatusSmall">
              Согласование
            </span>
          )
      }
  }
  
  export default EventStatusSmall;
import {React} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import { useForm } from "react-hook-form";
import { FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';


const AddStep1 = (props) => {
    const { register, handleSubmit, setValue, getValues, control, formState: { errors } } = useForm({
        defaultValues: {
            eventName: props.mainInfo.eventName,
            address: props.mainInfo.address,
            mainFio: props.mainInfo.mainFio,
            mainFioTel: props.mainInfo.mainFioTel,
            secondFio: props.mainInfo.secondFio,
            secondFioTel: props.mainInfo.secondFioTel,
            ...(props.mainInfo.dateStart === null ? 
                {dateStart: null} : 
                {dateStart: dayjs(props.mainInfo.dateStart, props.mainInfo.dateStart[4] === '-' ? 'YYYY-MM-DD':'DD.MM.YYYY')}),
            timeStart: props.mainInfo.timeStart,
            timeEnd: props.mainInfo.timeEnd,
            type: props.mainInfo.type
        }
    });
    const onSubmit = (data) => {
        let object = {
            'eventName': data.eventName,
            'address': data.address,
            'mainFio': data.mainFio,
            'mainFioTel': data.mainFioTel,
            'secondFio': data.secondFio,
            'secondFioTel': data.secondFioTel,
            'dateStart': data.dateStart.format('DD.MM.YYYY'),
            'timeStart': data.timeStart,
            'timeEnd': data.timeEnd,
            'type': data.type
        }
        props.handleGetMainInfo(object)
        props.handleSetStep(2)
      }    

    return(
        <div className='headBlock'>
            <div className="caption">Основная информация</div>
            <form className='formGrid' onSubmit={handleSubmit(onSubmit)}>
                <div className='inpRow'>
                        <div className="gr two">
                            <TextField fullWidth label="Название мероприятия" variant="outlined"
                            {...register("eventName", { required: true})}
                            error={!!errors?.eventName}
                            className="inpField"
                            helperText={errors.eventName && "Это поле обязательно"}/>
                        </div>
                </div>
                <div className='inpRow'>
                        <div className="gr two">
                            <TextField fullWidth label="Адрес" variant="outlined"
                            className="inpField"
                            {...register("address", { required: true})}
                            error={!!errors?.eventName}
                            helperText={errors.eventName && "Это поле обязательно"}/>
                        </div>
                </div>
                <div className='inpRow'>
                        <div className="gr two"> 
                            <FormControl>
                                <InputLabel id="typeSelectLabel">Тип выезда</InputLabel>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                    <Select {...field}
                                        labelId="typeSelectLabel"
                                        id="typeSelect"
                                        {...register("type", { required: true})}
                                        label="Тип выезда"
                                        value={getValues("type")}
                                        error={!!errors?.type}
                                        onChange={(event) => {setValue("type", event.target.value)}}>
                                            <MenuItem value='Пеший'>Пеший</MenuItem>
                                            <MenuItem value='Пеший'>Самостоятельный</MenuItem>
                                            <MenuItem value='Общественный транспорт'>Общественный транспорт</MenuItem>
                                            <MenuItem value='Автобус от организации'>Автобус от организации</MenuItem>
                                            <MenuItem value='Автобус от города'>Автобус от города</MenuItem>
                                    </Select>
                                    )}
                                />
                                
                                <FormHelperText error={!!errors?.type}>{errors.type && "Это поле обязательно"}</FormHelperText>
                            </FormControl>
                        </div>
                </div>
                <div className='inpRow'>
                    
                        <div className="gr two">
                                <TextField fullWidth label="Руководитель группы" variant="outlined" {...register("mainFio", { required: true})}
                                error={!!errors?.mainFio} helperText={errors.mainFio && "Это поле обязательно"}/>
                                <InputMask
                                    mask='+7(999)999-99-99'
                                    disabled={false}
                                    {...register("mainFioTel", { required: true, minLength: 16 })}
                                    maskChar=""
                                    defaultValue={props.mainInfo.mainFioTel}>
                                        {() => <TextField  fullWidth label="Телефон" 
                                        {...register("mainFioTel", { required: true, minLength: 16 })}
                                        helperText={errors.mainFioTel && "Введите корректный номер телефона"}
                                        error={!!errors?.mainFioTel} />}
                                </InputMask>                            
                        </div>
                </div>
                <div className='inpRow'>
                    <div className="gr two">
                            <TextField fullWidth label="Заместитель руководителя группы" variant="outlined" {...register("secondFio")}/>
                            <InputMask
                                mask='+7(999)999-99-99'
                                {...register("secondFioTel")}
                                disabled={false}
                                maskChar=""
                                defaultValue={props.mainInfo.secondFioTel}>
                                    {() => <TextField label="Телефон"
                                            {...register("secondFioTel")}/>}
                            </InputMask>
                    </div>
                </div>
                <div className="caption">Дата и время</div>
                <div className='inpRow'>
                    <div className="gr three">
                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} adapterLocale="ru" >
                            <Controller
                                name="dateStart"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <DatePicker fullWidth label="Дата выезда"
                                    onChange={(newValue) => setValue("dateStart", newValue.format('DD.MM.YYYY'))}
                                    {...field}
                                        slotProps={{
                                            textField: {
                                                helperText: errors.dateStart && "Введите корректное время",
                                                error: !!errors?.dateStart
                                            }
                                    }}/>
                                }
                            /> 
                                
                            </LocalizationProvider >
                            <InputMask
                                mask='99:99'
                                {...register("timeStart")}
                                disabled={false}
                                defaultValue={props.mainInfo.timeStart}
                                maskChar="">
                                    {() => <TextField 
                                        label="Время выезда"
                                        {...register("timeStart", { required: true, minLength: 5})}
                                        error={!!errors?.timeStart} 
                                        helperText={errors.timeStart && "Введите корректное время"}
                                    />}
                            </InputMask>
                            <InputMask
                                mask='99:99'
                                {...register("timeEnd")}
                                disabled={false}
                                defaultValue={props.mainInfo.timeEnd}
                                maskChar="">
                                    {() => <TextField 
                                        label="Время возвращения"
                                        {...register("timeEnd", { required: true, minLength: 5})}
                                        error={!!errors?.timeEnd} 
                                        helperText={errors.timeEnd && "Введите корректное время"}                        
                                    />}
                            </InputMask>
                    </div>
                </div>
                
                <div className='buttonRow'>
                    <Link to='/app/'>
                        <Button variant="contained" color="success">Отмена</Button>
                    </Link>
                    <Button variant="contained" color="success" type="submit" >Далее</Button>
                </div>
            </form>   
        </div>
    )
  }
  
  export default AddStep1;

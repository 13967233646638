import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppNew from './AppNew';
import AppView from './AppView';
import ViewOne from './components/ViewOne';
import Login from './components/Login';
import AppViewAll from './AppViewAll.js'
import AppEdit from './AppEdit';
import AppViewCoordZam from './AppViewCoordZam';
import AppEditZam from './components/AppEditZam';
import AppEditBus from './components/AppEditBus';
import AppViewCoordBus from './AppViewCoordBus';
import AppViewComplete from './AppViewComplete';
import AppViewGenerateDoc from './AppViewGenerateDoc'
import AppEditGenerateDoc from './components/AppEditGenerateDoc'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter >
      <Routes>
        <Route path="/app/" element={<AppView />} />
        <Route path="/app/all" element={<AppViewAll />} />
        <Route path="/app/edit" element={<AppEdit />} />
        <Route path="/app/editz" element={<AppEditZam />} />
        <Route path="/app/editb" element={<AppEditBus />} />
        <Route path="/app/edits" element={<AppEditGenerateDoc />} />
        <Route path="/app/new" element={<AppNew />} />
        <Route path="/app/view" element={<ViewOne />} />
        <Route path="/app/coordzam" element={<AppViewCoordZam />} />
        <Route path="/app/coordbus" element={<AppViewCoordBus />} />
        <Route path="/app/login" element={<Login />} />
        <Route path="/app/complete" element={<AppViewComplete />} />
        <Route path="/app/docs" element={<AppViewGenerateDoc />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
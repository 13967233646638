const ViewStudentTable = (props) => {

    const students = props.students.map((item, index) =>
        <tr className='tableTr' key={item.aisId}>
            <td className='tableTd'>{index + 1 + '.'}</td>
            <td className='tableTd'>{item.className}</td>
            <td className='tableTd'>{item.lastName} {item.firstName} {item.patronymic}</td>
            <td className='tableTd'>{item.birth}</td>
            <td className='tableTd'>{item.contacts.agentTel}</td>
        </tr>
    )

    return(
      <div>
        <div className="caption">{props.title}</div>
        <div className='predStr'>
            <table className='table'>
                <thead>
                    <tr className='tableTr'>
                        <th className='tableTh'>№</th>
                        <th className='tableTh'>Класс</th>
                        <th className='tableTh'>ФИО</th>
                        <th className='tableTh'>Дата рождения</th>     
                        <th className='tableTh'>Телефон представителя</th>   
                    </tr>
                </thead>
                <tbody>
                    {students}
                </tbody>
            </table>
        </div>
      </div>
    )
  }
    
    export default ViewStudentTable;
  
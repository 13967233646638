const ViewField = (props) => {
  return(
    <div>
        <div className="captionTitle">{props.title}:</div>
        <div>{props.value}</div>
    </div>
  )
}
  
  export default ViewField;

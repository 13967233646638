import React from 'react';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import EventStatusSmall from './views/EventStatusSmall';

const ViewList = (props) => {
    let events = <div>Активных заявок не найдено</div>
    if(props.eventsList.length > 0){
        events = props.eventsList.map((item) =>
            <div className="outString c__five__view" key={item.id}>
                <span className=''>{item.id}</span>
                
                <span className=''>{dayjs(item.dateStart).format('DD.MM.YYYY')}</span>
                <span className=''>
                    {item.eventName}
                </span>
                <span className=''>{item.type}</span>
                <span><EventStatusSmall workflowStep={item.workflowStep}/></span>
                <span className=''>
                    <Link to={'/app/' + props.viewPage} state={{ events_id: item.id }}>
                        <IconButton aria-label="delete" size="small" color="primary" >
                            <VisibilityIcon />
                        </IconButton>
                    </Link>
                    {props.buttonEdit && (item.workflowStep === 0 || item.workflowStep === 4) ? <Link to='/app/edit' state={{ events_id: item.id }}>
                        <IconButton aria-label="delete" size="small" color="primary" >
                            <EditIcon />
                        </IconButton>
                    </Link> : ''}
                </span>
            </div>
        )
    }
  
    return(
    <div >
        <div className="caption">Основная информация</div>
        <div className="outString c__five__view" >
            <span className="outString__head">Ид</span>
            <span className="outString__head">Дата</span>
            <span className="outString__head">Название мероприятия</span>
            <span className="outString__head">Тип</span>
            <span className="outString__head">Статус</span>
            <span className="outString__head">Действия</span>
        </div>
         {events}
        <div className='buttonRow'>
            <Link to='/app/new'>
                <Button variant="contained" color="success" >Добавить</Button>
            </Link>
        </div> 
    </div>
  )
}
export default ViewList;

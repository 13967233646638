import ViewField from "./ViewField.js"
import ViewHelpersTable from "./ViewHelpersTable.js";
import ViewStudentTable from "./ViewStudentTable.js";
import dayjs from "dayjs";

const ViewEvent = (props) => {
    const dateStart = props.dateInDayJs ? dayjs(props.mainInfo.dateStart, 'YYYY-MM-DD').format('DD.MM.YYYY') : props.mainInfo.dateStart
    console.log(dateStart)
  return(
    <div>
        <div className="caption">Основная информация</div>
        <div className="predStr gr two">
            <ViewField title="Название мероприятия" value={props.mainInfo.eventName} />
            <ViewField title="Адрес" value={props.mainInfo.address} />
        </div>
        <div className="predStr gr two">
            <ViewField title="Тип" value={props.mainInfo.type} />
        </div>
        <div className="predStr gr two">
            <ViewField title="Руководитель группы" value={props.mainInfo.mainFio} />
            <ViewField title="Телефон" value={props.mainInfo.mainFioTel} />
        </div>
        <div className="predStr gr two">
            <ViewField title="Заместитель руководителя группы" value={props.mainInfo.secondFio} />
            <ViewField title="Телефон" value={props.mainInfo.secondFioTel} />
        </div>
        <div className="caption">Дата и время</div>
        <div className="predStr gr three">
            <ViewField title="Дата выезда" value={dateStart} />
            <ViewField title="Время выезда" value={props.mainInfo.timeStart} />
            <ViewField title="Время возвращения" value={props.mainInfo.timeEnd} />
        </div>
        <ViewStudentTable students={props.students} title="Информация об обучающихся"/>
        <ViewHelpersTable helpers={props.helpers} title="Информация о сопровождающих" />
    </div>
  )
}
  
export default ViewEvent;

import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios'
import UserDataDialog from './UserDataDialog';
import {MenuDialog, BackScreen} from './MenuDialog';


const baseUrl = '/api/'

const Heading = (props) => {
  const[userData, setUserData] = useState([])
  const[showDialog, setShowDialog] = useState(false)
  const[showMenu, setShowMenu] = useState(false)

  const dialogToggle = () => {
    setShowDialog(!showDialog)
  }

  const menuToggle = () => {
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    axios.get(baseUrl + 'user/').then((res) => {
      setUserData(res.data)
    }).catch(function (error) {
      if(error.response.status===401){
        window.location.href = '/app/login/'
      }
    })
  }, [])

  return(
    <div className='heading'>
      <span className="title" onClick={menuToggle}>☰ {props.text}</span>
      <span className="profileImage" onClick={dialogToggle}>
        <img src={userData.picture} alt={userData.name} className={showDialog ? 'imageHovered' : ''}/>
      </span>
        <UserDataDialog userData={userData} hide={showDialog ? '' : 'hideTop'}/>
        <MenuDialog userRights={userData.rights} toggle={menuToggle} hide={showMenu ? '' : 'hideLeft'}/>
        {showMenu ? <BackScreen toggle={menuToggle}/> : ''}

    </div>
  )
}
  
  export default Heading;

import { useEffect, useState } from "react";
import axios from "axios"
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const baseUrl = '/api/'

const SearchStudents = (props) => {
    const [searchStudents, setSearchStudents] = useState('')
    const [studentsArray, setStudentsArray] = useState([])
    
    const search = () => {
        axios.get(baseUrl + 'student/' + searchStudents).then((res) => {
            setStudentsArray(res.data.filter((item) => props.studentsObject.indexOf(item.aisId) === -1))
        })
    }

    const addStudent = (id) => {
        axios.get(baseUrl + 'student/id/' + id).then((res) => {
            props.addToStudentsInfo(res.data[0])
            setStudentsArray(studentsArray.filter((item) => item.aisId !== id))
        })
    }

    const addAllStudent = async () => {
        let allStudentsArray = []
        for (const it of studentsArray) {
            await axios.get(baseUrl + 'student/id/' + it.aisId).then((res) => {
                allStudentsArray.push(res.data[0])
                setStudentsArray(studentsArray.filter((item) => item.aisId !== it.id))
            })
        }
        props.addAllStudentsInfo(allStudentsArray)
    }

    useEffect(() => {search()}, [searchStudents, props.reSearch])

    const students = studentsArray.map((item) =>
        <div className='searchString' key={item.aisId}>
            <span className='searchClass'>{item.className}</span>
            <span className='searchFio'>{item.fio}</span>
            <span className='searchAdd'>
                <IconButton aria-label="delete" size="small" color="primary" onClick={() => {addStudent(item.aisId)}}>
                    <AddCircleIcon />
                </IconButton>
            </span>
        </div>
    );

    return(
      <div >
        <div className='inpRow'>
            <TextField  fullWidth id="outlined-basic" label="Начните вводить имя или класс в формате 1-А"
                        variant="outlined" value={searchStudents}
                        onChange={(event) => setSearchStudents(event.target.value)}/>
            <Button variant="contained" color="success" onClick={addAllStudent}>Добавить всех</Button>
        </div>
        {students}
      </div>
    )
  }
  export default SearchStudents
  

import Button from '@mui/material/Button';

const Login = (props) => {
    return(
        <div className='loginCaption'>
        <span className="cabName">База выездов</span>
        <Button variant="contained" color="success" onClick={() => {window.location.href = '/api/login'}}>Войти через Google</Button>

        </div>
    )
  }
  export default Login;
  
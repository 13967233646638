import {React, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import SearchStudents from './SearchStudents';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const baseUrl = '/api/'

const AddStep2 = (props) => {
  const [modalView, setModalView] = useState(false)
  const [modalAisId, setModalAisId] = useState('')
  const [modalName, setModalName] = useState('')
  const [modalContactsData, setModalContactsData] = useState({})
  const handleBack = () => {
    props.handleSetStep(1)
  }

  useEffect(() => {
    editContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNext= () => {
    if(props.studentsInfo.length > 0){
      props.handleSetStep(3)
    }else{
      alert('Добавьте информацию об обучающихся')
    }
  }

  const modalToggleOn = (aisId, name) => {
    axios.get('/api/student/contacts/' + aisId).then((result) => {
      if(result.status === 200){
        setModalContactsData(result.data)
        setModalView(true)
        setModalAisId(aisId)
        setModalName(name)
      }
    }) 
  }

  const modalToggleOff = () => {
    axios.get(baseUrl + 'student/id/' + modalAisId).then((res) => {
      props.replaceStudentsInfo(res.data[0])
      setModalContactsData({})
      setModalView(false)
      setModalAisId('')
      setModalName('')
    })
  }

  const setDefaultNumber = (id) => {
    const json = JSON.stringify({'aisId': modalAisId});
    axios.post('/api/tel/set_default/' + id, json).then((result) => {
        if(result.status === 200){
          console.log('changed')
          modalToggleOff()
        }
    })
  }

  const rusTelephone = (tel) => {
    return ('+7(' + tel.slice(0,3) + ')' +
            tel.slice(3,6) + '-' +
            tel.slice(6,8) + '-' +
            tel.slice(8))

  }

  const editContacts = () => {
    console.log(modalView)
    if(modalView){
      return(
        <div>
          <div className='modalBack' onClick={() => modalToggleOff()}></div>
          <div 
              className='modalBlock'>
            <div className='caption'>
              Изменить номер телефона
            </div>
            <div>
              {modalName}
            </div>
            <br/>
            <div className='caption'>
              Выберите представителя:
            </div>
            {modalContactsData.contacts.map((item) => {
              return (<div className={item.defaultNumber === 1 ? 'outStringMarg c__four1 selected' : 'outStringMarg c__four1'}  key={item.id}>
                <span>{item.agentName}</span>
                <span>{item.agentStatus}</span>
                <span>{rusTelephone(item.agentTel)}</span>
                <span onClick={() => setDefaultNumber(item.id)}>выбрать</span>
              </div>)
            })}          
          </div>
        </div>
      )
    }else{
      return ''
    }
  }

  const students = () => {
    if(props.studentsInfo.length > 0){
      return(props.studentsInfo.map((item, index) =>
        <div className='outString c__six' key={item.aisId}>
            <span >{index + 1 + '.'}</span>
            <span >{item.className}</span>
            <span >{item.lastName} {item.firstName} {item.patronymic}</span>
            <span >{item.birth}</span>
            <span >{rusTelephone(item.contacts.agentTel)}</span>
            <span>
              <IconButton aria-label="delete" size="small" color="primary"
                onClick={() => {modalToggleOn(item.aisId, item.lastName + ' ' + item.firstName + ' ' + item.patronymic)}}>
                <EditIcon />
              </IconButton>  
              <IconButton aria-label="delete" size="small" color="primary"
                onClick={() => {props.delFromStudentsInfo(item.aisId)}}>
                <DeleteIcon />
              </IconButton>
            </span>
        </div>
      ))
    }else{
      return(<div className=''>Ни один учащийся не добавлен</div>)
    }
  }
  

  return(
        <div className='headBlock'>
            <div className="caption">Информация об обучающихся</div>
            <div className='formGrid'>
              <div className='inpRow'>
                {students()}
              </div>
              <div className="caption">Поиск</div>
              <div className='inpRow'>
                <SearchStudents studentsInfo={props.studentsInfo}
                                addToStudentsInfo={props.addToStudentsInfo}
                                selectedStudents={props.studentsInfo}
                                studentsObject={props.studentsObject}
                                reSearch={props.reSearch}
                                addAllStudentsInfo = {props.addAllStudentsInfo}/>
              </div>
              <div className='buttonRow'>
                <Button variant="contained" color="success" onClick={handleBack}>Назад</Button>
                <Button variant="contained" color="success" onClick={handleNext}>Далее</Button>
              </div>
              {editContacts()}
            </div>
      </div>
    )
  }
  
  export default AddStep2;

import { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputMask from "react-input-mask";
import { useEffect } from "react";



const AddPeople = (props) => {
    const [fio, setFio] = useState('')
    const [tel, setTel] = useState('')
    const [fioCheck, setFioCheck] = useState(true)
    const [telCheck, setTelCheck] = useState(true)
    const [addCheck, setAddCheck] = useState(false)

    useEffect(() => {
      fio.length > 3 ? setFioCheck(true) : setFioCheck(false)
      tel.replaceAll(' ', '').length > 15 ? setTelCheck(true) : setTelCheck(false)},
    [fio, tel])

    const add = () => {
      setAddCheck(true)
      if(fioCheck && telCheck){
        props.addToDopInfo(fio, tel)
        setFio('')
        setTel('')
        setAddCheck(false)
      }
    }

    return(
      <div >
        <div className='inpRow'>
          <div className="gr two">
            <TextField  fullWidth id="outlined-basic" label="Фамилия Имя Отчество"
                        variant="outlined" value={fio}
                        error={!fioCheck && addCheck}
                        helperText={!fioCheck && addCheck ? 'Введите ФИО' : ''}
                        onChange={(event) => setFio(event.target.value)}/>
            <InputMask
                mask='+7(999)999-99-99'
                value={tel}
                onChange={(event) => setTel(event.target.value)}
                disabled={false}
                maskChar=" ">
                    {() => <TextField label="Телефон" error={!telCheck && addCheck}
                    helperText={!telCheck && addCheck ? 'Введите корректный телефон' : ''}/>}
            </InputMask>
          </div>
        </div>
        <div className='inpRow'>
            <Button variant="contained" color="success" 
              onClick={add}>
                Добавить
            </Button>
        </div>

      </div>
    )
  }
  export default AddPeople
  
import { Link,useLocation } from "react-router-dom";

const MenuDialog = (props) => {
  const location = useLocation().pathname;

  if(typeof(props.userRights) !== 'undefined'){
    return(
        <div className={'menuDialog ' + props.hide}  onClick={() => {return false}}>
          <Link to='/app/' >
            <span className={location === "/app/" ? "cabName current" : "cabName"}>Мои заявки</span>
          </Link>
          
          {props.userRights.coord_zam ? 
          <Link to='/app/coordzam'>
            <span className={location === '/app/coordzam' ? "cabName current" : "cabName"}>Требуют согласования</span>
          </Link> : ''}
          {props.userRights.coord_bus ? 
          <Link to='/app/coordbus'>
            <span className={location === '/app/coordbus' ? "cabName current" : "cabName"}>Требуют согласования</span>
          </Link> : ''}
          {props.userRights.secretary ? 
            <Link to='/app/docs' >
              <span className={location === "/app/docs" ? "cabName current" : "cabName"}>Изготовление документов</span>
            </Link> : ''}
          {props.userRights.viewall ? 
            <Link to='/app/all' >
              <span className={location === "/app/all" ? "cabName current" : "cabName"}>Все заявки</span>
            </Link> : ''}
          <Link to='/app/complete'>
            <span className={location === '/app/complete' ? "cabName current" : "cabName"}>Обработано</span>
          </Link>
        </div>
    )
  }
}


const BackScreen = (props) => {
  return(
    <div className='menuBack' onClick={props.toggle}>
    </div>
  )
}
  
export {BackScreen, MenuDialog}

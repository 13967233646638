import axios from 'axios'
import { useEffect, useState } from 'react';

const baseUrl = '/api/'

const EventCreater = (props) => {
    const[createdData, setCreatedData] = useState({})
    const[editedData, setEditedData] = useState({})
    useEffect(() => {
        if(props.userCreated){
            axios.get(baseUrl + 'user/' + props.userCreated).then((res) => {
                setCreatedData(res.data)
            })
        }
      }, [props.userCreated])
    useEffect(() => {
        if(props.userEdited){
            axios.get(baseUrl + 'user/' + props.userEdited).then((res) => {
                setEditedData(res.data)
            })
        }
    }, [props.userEdited])
    return(
        <div className='userInfo'>
            <div>Кем создано: {createdData.name}</div>
            <div>Кем изменено: {editedData.name}</div>
        </div>
    )
  }
  
  export default EventCreater;
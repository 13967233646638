import Button from '@mui/material/Button';

const UserDataDialog = (props) => {
  return(
    <div className={'userDialog ' + props.hide}>
      <div>{props.userData.family_name}</div>
      <div>{props.userData.given_name}</div>
      <div>
        <Button variant="outlined" color="error" onClick={() => {window.location.href = '/api/logout'}}>Выйти</Button>
      </div>
    </div>
  )
}
  
  export default UserDataDialog;

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddPeople from './AddPeople';

const AddStep3 = (props) => {

    const handleBack = () => {
        props.handleSetStep(2)
    }
    
    const handleNext= () => {
        props.handleSetStep(4)
    }
    

    const helpers = () => {
        console.log(props.dopInfo)
        if(props.dopInfo.length > 0){
          return(props.dopInfo.map((item, index) =>
            <div className='outString c__four' key={item}>
                <span>{index + 1 + '.'}</span>
                <span>{item.fio}</span>
                <span>{item.tel}</span>
                <span>
                    <IconButton aria-label="delete" size="small" color="primary" onClick={() => props.delFromDopInfo(item)}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </div>
          ))
        }else{
          return(<div className=''>Ни один сопровождающий не добавлен</div>)
        }
      }


  return(
    <div className='headBlock'>
        <div className="caption">Информация о сопровождающих</div>
            <div className='formGrid'>
              <div className='inpRow'>
                {helpers()}
              </div>
              <div className="caption">Добавить сопровождающего</div>
              <div className='inpRow'>
                <AddPeople addToDopInfo={props.addToDopInfo}/>
              </div>
              <div className='buttonRow'>
                <Button variant="contained" color="success" onClick={handleBack}>Назад</Button>
                <Button variant="contained" color="success" onClick={handleNext}>Предпросмотр</Button>
              </div>
            </div>
      </div>
  )
}
export default AddStep3;

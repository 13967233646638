import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import Heading from './components/Heading';
import axios from 'axios'
import ViewList from './components/ViewList';

const baseUrl = '/api/'

const AppViewComplete = () => {
  const[eventsList, setEventsList] = useState([])

  useEffect(() => {getInformation()}, [])

  const getInformation = () => {
    axios.get(baseUrl + 'allevents/2').then((res) => {
      setEventsList(res.data)
    })
  }
  return(
    <div >
      <Heading text="Согласованные заявки"/>
      <div className='headBlock'>
        <ViewList eventsList={eventsList} buttonEdit={false} viewPage="editz"/>
      </div>
    </div>
  )
}
export default AppViewComplete;
